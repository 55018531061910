import React from 'react'
import {logosData} from '../../data/logos'

export const Logos: React.FC = () => {
  return (
    <div id="logos" className="bg-neutral-900 py-10">
      <div className="mx-auto max-w-7xl px-8">
        <div className="flex flex-wrap items-end justify-center gap-2">
          {logosData.map((logo, index) => {
            return (
              <div
                key={index}
                className="relative w-52 rounded-lg text-center shadow-sm shadow-neutral-400 md:w-64 lg:w-1/4"
              >
                <img
                  className="w-full rounded-lg grayscale"
                  width="300"
                  height="169"
                  src={logo.imageLocation || ''}
                  alt={logo.title || "logo"}
                />
                {logo.link && (
                  <a
                    href={logo.link}
                    target="_blank"
                    rel="noreferrer"
                    className="absolute top-0 left-0 z-10 h-full w-full cursor-pointer rounded-lg bg-gray-500/75 opacity-0 transition-opacity duration-300 hover:opacity-100"
                  >
                    {logo.title && (
                      <h3 className="mx-auto py-6 px-3 text-xl text-white">
                        {logo.title}
                      </h3>
                    )}
                  </a>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
