import React, {useEffect, useRef} from 'react'
import {useSetRecoilState, atom} from 'recoil'
import { usePageScrollTop } from '../../hooks/usePageScrollTop'
import {useWindowSize} from '../../hooks/useWindowSize'

export const pageHeightAtom = atom<number>({
  key: 'pageHeightAtomState',
  default: 0,
})

interface Props {
  children?: React.ReactNode[]
}

export const PageContainer: React.FC<Props> = (props) => {
  const windowSize = useWindowSize()
  const scrollTop = usePageScrollTop()
  const setPageHeight = useSetRecoilState(pageHeightAtom)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setPageHeight(ref?.current?.clientHeight ?? 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize, scrollTop])

  return (
    <div className="bg-white" ref={ref}>
      {props.children}
    </div>
  )
}
