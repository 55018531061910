import {Translation} from '../models/Translation'

export const about: Translation = {
  english: [
    {
      text: 'Born in Naples, I have been living in Milan for twenty years. I entered the audiovisual field as an autodidact, then grew professionaly among various agencies and production companies. The role I have been usually appointed to is Video Editor but, when needed, I can also work as a Motion Graphic Designer and Camera Operator. I love to deal with social topics, and during the years I worked on personal projects writing and directing documentaries: among them "A mio padre" (from the works section), winner of the best scriptwriting award at MigrArti 2017, competition organized by MIBACT and presented at the Venice Film Festival 2017.',
    },
    {
      text: 'This world has given me the possibility to express what I really want and what I truly care about. In fact I believe that video is an empathetic visual art that can truly connect with the audience. I live and work following the idea of making the impossible possible. My goal is to become the type of person people can rely on.',
    },
    {
      text: "So, what am I? I'm a Video Editor. And a Motion Graphic Designer. And a Camera Operator. And...why not? I'm a documentary Film Maker.",
    },
  ],
  italian: [
    {
      text: 'Nato a Napoli, vivo a Milano da venti anni. Ho mosso i primi passi nell\'ambito audiovisivo da autodidatta, crescendo poi tra agenzie e case di produzione. Il ruolo che di solito assumo è quello di Video Editor ma, se richiesto, sono in grado di lavorare come Motion Graphic Designer e Operatore di camera. Amo trattare di argomenti sociali e negli anni ho lavorato a prodotti personali, dedicandomi alla scrittura e alla regia di documentari. Tra questi, il documentario "A mio padre" (presente nella galleria dei lavori) è stato vincitore del premio per miglior sceneggiatura a MigrArti 2017, concorso indetto dal MIBACT e presentato durante il Festival di Venezia 2017.',
    },
    {
      text: "Ho trovato in questo mondo la possibilità di trasmettere tutto quello che vorrei comunicare: sono dell'idea, infatti, che il video sia un mezzo empatico che ci permette di avvicinarci agli altri. Vivo e lavoro seguendo l'idea di rendere possibili le cose impossibili. Il mio obiettivo? Essere la persona su cui chiunque può fare affidamento.",
    },
    {
      text: 'Quindi, chi sono? Sono un Video Editor. E un Motion Graphic Designer. E un Operatore di camera. E...perché no? Anche un Regista di documentari.',
    },
  ],
}
