import React from 'react'
import {VideoDescription} from '../../models/Portfolio'

interface Props {
  title?: string
  videoDesciption: VideoDescription[]
  note?: string
}

export const Description: React.FC<Props> = (props) => {
  return (
    <div
      className="absolute left-1/2 mt-9 w-full max-w-xl -translate-x-1/2 px-1 pb-6"
      onClick={(e) => e.stopPropagation()}
    >
      {props.title && (
        <h2 className="mb-6 text-center text-sm font-medium text-white max-sm:text-[11px]">
          {props.title}
        </h2>
      )}
      <div className="grid grid-cols-1 gap-0 max-sm:gap-2">
        {props.videoDesciption.map((description, index) => (
          <div
            key={index}
            className="grid grid-cols-2 items-baseline gap-3 max-sm:gap-2"
          >
            <span className="justify-self-end text-sm text-neutral-400 max-sm:text-[11px]">
              {description.role}
            </span>
            <span className="justify-self-start text-sm font-medium text-white max-sm:text-[11px]">
              {description.name}
            </span>
          </div>
        ))}
      </div>
      {props.note && (
        <h2 className="text-center text-sm font-extralight text-white">
          {props.note}
        </h2>
      )}
    </div>
  )
}
