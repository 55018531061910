import React, {useEffect, useState} from 'react'
import {useRecoilValue} from 'recoil'
import {contact} from '../../data/contact'
import {Email} from '../../models/Email'
import {Paragraph} from '../../models/Translation'
import {sendMail} from '../../services/sendMail'
import {websiteLanguage} from '../Header/LanguageSwitch'

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export const Contact: React.FC = () => {
  const [content, setContent] = useState<Paragraph[]>([])
  const language = useRecoilValue(websiteLanguage)

  const [formData, setFormData] = useState<Email>({
    name: '',
    email: '',
    message: '',
  })
  const [nameHasError, setNameHasError] = useState(false)
  const [emailHasError, setEmailHasError] = useState(false)
  const [messageHasError, setMessageHasError] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [formDisabled, setFormDisabled] = useState(false)
  const [formSubmissionSucceded, setFormSubmissionSucceded] = useState<
    boolean | undefined
  >(undefined)

  useEffect(() => {
    language === 'ita'
      ? setContent(contact.italian)
      : setContent(contact.english)
  }, [language])

  useEffect(() => {
    nameHasError || emailHasError || messageHasError
      ? setButtonDisabled(true)
      : setButtonDisabled(false)
  }, [nameHasError, emailHasError, messageHasError])

  const triggerValidation = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (e.target.id) {
      case 'contact-form-name':
        formData.name ? setNameHasError(false) : setNameHasError(true)
        break
      case 'contact-form-email':
        formData.email && validateEmail(formData.email)
          ? setEmailHasError(false)
          : setEmailHasError(true)
        break
      case 'contact-form-message':
        formData.message ? setMessageHasError(false) : setMessageHasError(true)
        break
    }
  }

  const validateForm = (): boolean => {
    if (
      formData.name &&
      formData.email &&
      validateEmail(formData.email) &&
      formData.message
    )
      return true
    formData.name ? setNameHasError(false) : setNameHasError(true)
    formData.email && validateEmail(formData.email)
      ? setEmailHasError(false)
      : setEmailHasError(true)
    formData.message ? setMessageHasError(false) : setMessageHasError(true)
    return false
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (e.target.id) {
      case 'contact-form-name':
        setFormData((previousState) => {
          return {...previousState, name: e.target.value}
        })
        break
      case 'contact-form-email':
        setFormData((previousState) => {
          return {...previousState, email: e.target.value}
        })
        break
      case 'contact-form-message':
        setFormData((previousState) => {
          return {...previousState, message: e.target.value}
        })
        break
    }
  }

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (validateForm()) {
      setButtonDisabled(true)
      setFormDisabled(true)
      sendMail(formData).then((res) => {
        if (res === false) {
          setFormSubmissionSucceded(false)
          setFormDisabled(false)
          setButtonDisabled(false)
        } else {
          setFormSubmissionSucceded(true)
        }
      })
    }
  }

  return (
    <div id="contact" className="bg-orange-400 py-10">
      <div className="mx-auto flex max-w-7xl flex-col px-8 text-neutral-900">
        <h2 className="w-full pb-2 text-center text-3xl font-extrabold leading-9">
          Contact me
        </h2>
        {content.map((paragraph, index) => (
          <span key={index} className="text-center">
            {paragraph.text}
          </span>
        ))}
        <span className="pt-2 text-center">
          <a className="hover:text-white" href="tel:+393409234390">
            +39 340 92 34 390
          </a>{' '}
          &nbsp;<span className="font-normal">||</span>&nbsp;{' '}
          <a className="hover:text-white" href="mailto:info@laderasvideo.com">
            info@laderasvideo.com
          </a>
        </span>
        <form
          className="mx-auto flex w-full max-w-md pt-4 md:w-2/3"
          onSubmit={submitForm}
        >
          <div className="m-auto w-full max-w-2xl rounded-lg bg-neutral-900 px-5 py-10 shadow">
            <div className="m-auto grid max-w-xl grid-cols-2 gap-4">
              <div className="col-span-2 lg:col-span-1">
                <div className="relative">
                  <input
                    type="text"
                    id="contact-form-name"
                    className="w-full flex-1 appearance-none rounded-lg border border-transparent border-neutral-300 bg-white py-2 px-4 text-base text-neutral-900 placeholder-neutral-400 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-orange-600 disabled:bg-neutral-300"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    onBlur={triggerValidation}
                    disabled={formDisabled}
                  />
                  {nameHasError && (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        fill="currentColor"
                        className="absolute right-2 bottom-3 text-red-500"
                        viewBox="0 0 1792 1792"
                      >
                        <path d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z"></path>
                      </svg>
                      <p className="absolute -bottom-6 text-sm text-red-500">
                        Name is empty
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-span-2 lg:col-span-1">
                <div className="relative mt-3 lg:mt-0">
                  <input
                    type="email"
                    id="contact-form-email"
                    className="w-full flex-1 appearance-none rounded-lg border border-transparent border-neutral-300 bg-white py-2 px-4 text-base text-neutral-900 placeholder-neutral-400 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-orange-600 disabled:bg-neutral-300"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    onBlur={triggerValidation}
                    disabled={formDisabled}
                  />
                  {emailHasError && (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        fill="currentColor"
                        className="absolute right-2 bottom-3 text-red-500"
                        viewBox="0 0 1792 1792"
                      >
                        <path d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z"></path>
                      </svg>
                      <p className="absolute -bottom-6 text-sm text-red-500">
                        Email is invalid
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-span-2">
                <div className="relative mt-3">
                  <textarea
                    className="w-full flex-1 appearance-none rounded-lg border border-neutral-300 bg-white px-4 py-2 text-base text-neutral-900 placeholder-neutral-400 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-orange-600 disabled:bg-neutral-300"
                    id="contact-form-message"
                    placeholder="Enter your message..."
                    name="message"
                    rows={5}
                    cols={40}
                    value={formData.message}
                    onChange={handleInputChange}
                    onBlur={triggerValidation}
                    disabled={formDisabled}
                  />
                  {messageHasError && (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        fill="currentColor"
                        className="absolute right-2 top-3 text-red-500"
                        viewBox="0 0 1792 1792"
                      >
                        <path d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z"></path>
                      </svg>
                      <p className="absolute -bottom-5 text-sm text-red-500">
                        Message is empty
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-span-2 mx-auto mt-3 w-1/3 max-w-sm text-right">
                <button
                  type="submit"
                  disabled={buttonDisabled}
                  className="w-full rounded-lg bg-orange-800 py-2 px-4 text-center text-base font-semibold text-white shadow-md transition duration-200 ease-in hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2  focus:ring-offset-orange-200 disabled:bg-neutral-500"
                >
                  Send
                </button>
              </div>
              {formSubmissionSucceded !== undefined && (
                <div className="col-span-2 flex items-center justify-center align-middle">
                  <>
                    {formSubmissionSucceded === false && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        fill="currentColor"
                        className="mr-2 text-red-500"
                        viewBox="0 0 1792 1792"
                      >
                        <path d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z"></path>
                      </svg>
                    )}
                    <p
                      className={`text-md ${
                        formSubmissionSucceded === false
                          ? 'text-red-500'
                          : 'text-green-500'
                      }`}
                    >
                      {formSubmissionSucceded === false
                        ? 'Faild to send message. Try again later.'
                        : "Message sent! You'll hear back from me in a few days."}
                    </p>
                  </>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
