import React, {useEffect} from 'react'
import {css} from '@emotion/css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {usePageScrollTop} from '../../hooks/usePageScrollTop'
import {useWindowSize} from '../../hooks/useWindowSize'
import {useRecoilValue} from 'recoil'
import {pageHeightAtom} from '../PageContainer'
import {footerHeightAtom} from '../Footer'

export const Socials: React.FC = () => {
  const [bottomCSSClass, setBottomCSSClass] = React.useState<string>('bottom-0')
  const scrollTop = usePageScrollTop()
  const windowSize = useWindowSize()
  const pageHeight = useRecoilValue(pageHeightAtom)
  const footerHeight = useRecoilValue(footerHeightAtom)

  useEffect(() => {
    const isScrollPastFooter =
      windowSize.height + scrollTop + footerHeight > pageHeight
    if (isScrollPastFooter) {
      setBottomCSSClass(
        css`
          bottom: ${footerHeight -
          (pageHeight - (windowSize.height + scrollTop))}px;
        `
      )
    } else {
      setBottomCSSClass('bottom-0')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize, scrollTop, pageHeight])

  return (
    <div className={`fixed ${bottomCSSClass} right-0 z-10`}>
      <div className="mr-3 mb-5 flex flex-col gap-1 text-neutral-200 md:mr-5 md:mb-5 md:flex-row">
        <a
          href="http://www.vimeo.com/gagl"
          aria-label="Go to my Vimeo page"
          target="_blank"
          rel="noreferrer"
          className="flex h-8 w-8 items-center justify-center rounded-full bg-neutral-500 p-1 hover:text-white md:h-10 md:w-10"
        >
          <FontAwesomeIcon
            icon={icon({name: 'vimeo-v', style: 'brands'})}
            className="h-4 w-4 md:h-5 md:w-5"
          />
        </a>
        <a
          href="https://www.linkedin.com/in/gabriel-laderas"
          aria-label="Go to my Linkedin page"
          target="_blank"
          rel="noreferrer"
          className="flex h-8 w-8 items-center justify-center rounded-full bg-neutral-500 p-1 hover:text-white md:h-10 md:w-10"
        >
          <FontAwesomeIcon
            icon={icon({name: 'linkedin-in', style: 'brands'})}
            className="h-4 w-4 md:h-5 md:w-5"
          />
        </a>
        <a
          href="https://www.instagram.com/laderasvideo/"
          aria-label="Go to my Instagram page"
          target="_blank"
          rel="noreferrer"
          className="flex h-8 w-8 items-center justify-center rounded-full bg-neutral-500 p-1 hover:text-white md:h-10 md:w-10"
        >
          <FontAwesomeIcon
            icon={icon({name: 'instagram', style: 'brands'})}
            className="h-4 w-4 md:h-5 md:w-5"
          />
        </a>
      </div>
    </div>
  )
}
