import React from 'react'
import {portfolioData} from '../../data/portfolio'
import {Thumbnail} from './Thumbnail'

export const Portfolio: React.FC = () => {
  return (
    <div id="portfolio" className="bg-neutral-900 py-10">
      <div className="mx-auto flex max-w-7xl flex-col px-8">
        <h2 className="w-full text-center text-3xl font-extrabold leading-9 text-white">
          Works
        </h2>
        <div className="flex flex-wrap items-end justify-center gap-2 pt-10">
          {portfolioData.map((data, index) => (
            <Thumbnail key={index} {...data} />
          ))}
        </div>
      </div>
    </div>
  )
}
