import {Email} from '../models/Email'

export const sendMail = async (email: Email): Promise<boolean> => {
  const res = await fetch('/api/sendContactMail', {
    body: JSON.stringify(email),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })

  const {error} = await res.json()
  if (error) {
    console.log(error)
    return false
  }
  return true
}
