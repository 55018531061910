import {Portfolio} from '../models/Portfolio'

export const portfolioData: Portfolio[] = [
  {
    name: 'ROMANIA || HOMESICK',
    description: [
      {
        role: 'Director',
        name: 'Gabriel Laderas',
      },
      {
        role: 'Camera Operator',
        name: 'Gabriel Laderas',
      },
      {
        role: 'Editor',
        name: 'Gabriel Laderas',
      },
      {
        role: 'Talent',
        name: 'Sabin Rimniceanu',
      },
    ],
    thumbnail: '/assets/thumbnails/ROMANIA.webp',
    vimeoLink: 'https://vimeo.com/807470494',
  },
  {
    name: 'GRAN TOUR',
    description: [
      {
        role: 'Director',
        name: 'Gabriel Laderas',
      },
      {
        role: 'DOP & Camera Operator',
        name: 'Karina Cassani',
      },
      {
        role: '2nd Camera',
        name: 'Gabriel Laderas',
      },
      {
        role: 'Editor',
        name: 'Gabriel Laderas',
      },
      {
        role: 'Stylist',
        name: 'Arianna Frison',
      },
      {
        role: 'Makeup Artist',
        name: 'Emanuele Sofia',
      },
      {
        role: 'Model',
        name: 'Rebecca Klimova',
      },
    ],
    thumbnail: '/assets/thumbnails/GRAN_TOUR.webp',
    vimeoLink: 'https://vimeo.com/804672661',
  },
  {
    name: 'A MIO PADRE',
    title:
      'Best Screenplay Award @Migrarti2017 - 74th Venice International Film Festival',
    description: [
      {
        role: 'Director, Photography, Sound',
        name: 'Gabriel Laderas, Alessio Tamborini',
      },
      {
        role: 'Script',
        name: 'Gabriel Laderas',
      },
      {
        role: 'Editing',
        name: 'Gabriel Laderas, Alessio Tamborini',
      },
      {
        role: 'Original Soundtracks',
        name: 'Gabriel Laderas, Stefano Laruccia',
      },
      {
        role: 'Mix',
        name: 'Tommaso Barbaro',
      },
      {
        role: 'Color Grading',
        name: 'EDI - Effetti Digitali Italiani',
      },
      {
        role: 'Excecutive Producer',
        name: 'Laura Zagordi',
      },
      {
        role: 'Art Direction',
        name: 'Tonino Curagi, Anna Gorio',
      },
      {
        role: 'Production Director',
        name: 'Cristina Catalani',
      },
      {
        role: 'Production',
        name: 'Civica Scuola di Cinema Luchino Visconti',
      },
      {
        role: 'Collaboration',
        name: 'Naga',
      },
    ],
    thumbnail: '/assets/thumbnails/A_MIO_PADRE.webp',
    vimeoLink: 'https://vimeo.com/350498539',
  },
  {
    name: 'RESTIVOIL || OOOH!',
    description: [
      {
        role: 'Client',
        name: 'RESTIVOIL',
      },
      {
        role: 'Director',
        name: 'Sergio Bosatra',
      },
      {
        role: 'Editor',
        name: 'Gabriel Laderas',
      },
      {
        role: 'Agency',
        name: 'RED',
      },
      {
        role: 'Production',
        name: 'PEPERONCINO STUDIO',
      },
    ],
    thumbnail: '/assets/thumbnails/RESTIVOIL.webp',
    vimeoLink: 'https://vimeo.com/807471578',
  },
  {
    name: 'CARRERA',
    description: [
      {
        role: 'Client',
        name: 'CARRERA',
      },
      {
        role: 'Director',
        name: 'Riccardo Vimercati',
      },
      {
        role: 'Editor',
        name: 'Gabriel Laderas',
      },
      {
        role: 'Colorist',
        name: 'Giorgia Meacci',
      },
    ],
    thumbnail: '/assets/thumbnails/CARRERA.webp',
    vimeoLink: 'https://vimeo.com/804670284',
  },
  {
    name: 'MAX&CO',
    description: [
      {
        role: 'Client',
        name: 'MAX&CO',
      },
      {
        role: 'Director',
        name: 'Riccardo Vimercati',
      },
      {
        role: 'Editor',
        name: 'Gabriel Laderas',
      },
      {
        role: 'Colorist',
        name: 'Giorgia Meacci',
      },
    ],
    thumbnail: '/assets/thumbnails/MAX&CO.webp',
    vimeoLink: 'https://vimeo.com/804670998',
  },
  {
    name: 'GMT || SHOWREEL',
    description: [
      {
        role: 'Client',
        name: 'GMT',
      },
      {
        role: 'DOP & Camera Operator',
        name: 'Alessio Tamborini',
      },
      {
        role: 'Editor',
        name: 'Gabriel Laderas',
      },
      {
        role: 'Colorist',
        name: 'Gabriel Laderas',
      },
    ],
    thumbnail: '/assets/thumbnails/GMT.webp',
    vimeoLink: 'https://vimeo.com/804672395',
  },
  {
    name: 'FABSCARTE || WALL LAMP',
    description: [
      {
        role: 'Client',
        name: 'FABSCARTE',
      },
      {
        role: 'Director & Photography',
        name: 'Monica Spezia',
      },
      {
        role: 'Video Editor',
        name: 'Gabriel Laderas',
      },
    ],
    thumbnail: '/assets/thumbnails/FABSCARTE.webp',
    vimeoLink: 'https://vimeo.com/804671818',
  },
  {
    name: 'ROMETTI',
    description: [
      {
        role: 'Client',
        name: 'ROMETTI',
      },
      {
        role: 'Director & Photography',
        name: 'Monica Spezia',
      },
      {
        role: 'Video Editor',
        name: 'Gabriel Laderas',
      },
      {
        role: 'Production',
        name: 'Living Inside',
      },
    ],
    thumbnail: '/assets/thumbnails/ROMETTI.webp',
    vimeoLink: 'https://vimeo.com/807472055',
  },
]
