import React from 'react'
import {useRecoilValue} from 'recoil'
import {videoLoading} from './Detail'

interface Props {
  children?: React.ReactNode
}

export const PlayerContainer: React.FC<Props> = (props: Props) => {
  const loading = useRecoilValue(videoLoading)

  return (
    <div className={`mx-auto w-fit ${loading ? 'hidden' : ''}`}>
      <div className="h-[22rem] w-[42rem] max-sm:h-[12rem] max-sm:w-[22rem] md:h-[26rem] md:w-[50rem] lg:h-[32rem] lg:w-[58rem]">
        {props.children}
      </div>
    </div>
  )
}
