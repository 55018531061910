import React, {useState, useEffect} from 'react'
import {Paragraph} from '../../models/Translation'
import {about} from '../../data/about'
import {useRecoilValue} from 'recoil'
import {websiteLanguage} from '../Header/LanguageSwitch'

export const About: React.FC = () => {
  const [content, setContent] = useState<Paragraph[]>([])
  const language = useRecoilValue(websiteLanguage)

  useEffect(() => {
    language === 'ita' ? setContent(about.italian) : setContent(about.english)
  }, [language])

  return (
    <div id="about" className="bg-white py-10">
      <div className="mx-auto flex max-w-7xl flex-col px-8">
        <h2 className="w-full text-center text-3xl font-extrabold leading-9 text-neutral-900">
          About me
        </h2>
        <div className="mx-auto flex max-w-6xl flex-col pt-10 text-neutral-900 lg:flex-row">
          <div className="mx-auto w-full text-left md:w-2/3 md:text-justify">
            {content.map((paragraph, index) => (
              <div key={index} className="mb-4">
                <p>{paragraph.text}</p>
              </div>
            ))}
          </div>
          <img
            className="mx-auto h-min w-min rounded-lg bg-white pl-5 max-[1023px]:pl-0 lg:rounded-b-none"
            src="/assets/FOTO_ABOUT_ME_02.webp"
            alt="Gabriel Laderas"
          />
        </div>
      </div>
    </div>
  )
}
