import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {DropDownMenu} from './DropDownMenu'
import {ReactComponent as Logo} from './assets/LOGO_DISEGNO.svg'
import {ReactComponent as LogoText} from './assets/LOGO_SCRITTA.svg'
import {LanguageSwitch} from './LanguageSwitch'
import {scrollToElement} from './scrollToElement'

export const Header: React.FC = () => {
  return (
    <header className="sticky top-0 z-20 shadow-md">
      <nav className="bg-white">
        <div className="mx-auto max-w-7xl px-8">
          <div className="h-16 md:h-20">
            <div className="grid w-full grid-cols-2 grid-rows-1 items-center">
              <a
                href="/"
                aria-label="Go to the home page of Gabriel Laderas' video editor and documentary director portfolio."
                className="flex flex-row justify-self-start fill-neutral-900 hover:fill-orange-400"
              >
                <Logo className="h-16 w-16 pt-2 max-sm:hidden md:h-20 md:w-20" />
                <LogoText className="h-16 w-28 md:h-20 md:w-36" />
              </a>
              <div className="flex items-center justify-self-end text-neutral-900">
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    <div
                      className="cursor-pointer rounded-md px-3 py-2 text-lg font-medium hover:text-orange-400"
                      onClick={() => scrollToElement('portfolio')}
                    >
                      Works
                    </div>
                    <div
                      className="cursor-pointer rounded-md px-3 py-2 text-lg font-medium hover:text-orange-400"
                      onClick={() => scrollToElement('about')}
                    >
                      About
                    </div>
                    <div
                      className="cursor-pointer rounded-md px-3 py-2 text-lg font-medium hover:text-orange-400"
                      onClick={() => scrollToElement('contact')}
                    >
                      Contact
                    </div>
                    <div className="rounded-md py-2 text-xl font-light">|</div>
                  </div>
                </div>
                <LanguageSwitch />
                <div className="flex md:hidden">
                  <DropDownMenu
                    id="navigation-menu"
                    icon={
                      <FontAwesomeIcon
                        icon={icon({name: 'bars', style: 'solid'})}
                        className="h-6 w-6"
                      />
                    }
                    items={[
                      {
                        label: 'Works',
                        onClick: () => scrollToElement('portfolio'),
                      },
                      {label: 'About', onClick: () => scrollToElement('about')},
                      {
                        label: 'Contact',
                        onClick: () => scrollToElement('contact'),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}
