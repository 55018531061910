import React from 'react'
import {RecoilRoot} from 'recoil'
import {About} from './components/About'
import {Contact} from './components/Contact'
import {Footer} from './components/Footer'
import {Header} from './components/Header'
import {Logos} from './components/Logos'
import {PageContainer} from './components/PageContainer'
import {Portfolio} from './components/Portfolio'
import {Socials} from './components/Socials'

export const App: React.FC = () => {
  return (
    <RecoilRoot>
      <PageContainer>
        <Header />
        <Portfolio />
        <About />
        <Logos />
        <Contact />
        <Footer />
      </PageContainer>
      <Socials />
    </RecoilRoot>
  )
}
