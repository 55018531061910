import React, {useEffect, useRef, useState} from 'react'
import {atom, useSetRecoilState} from 'recoil'
import {useWindowSize} from '../../hooks/useWindowSize'

const CREATION_YEAR = 2023

export const footerHeightAtom = atom<number>({
  key: 'footerHeightAtomState',
  default: 0,
})

export const Footer: React.FC = () => {
  const [currentYear] = useState<number>(new Date().getFullYear())
  const windowSize = useWindowSize()
  const setFooterHeight = useSetRecoilState(footerHeightAtom)
  const ref = useRef<HTMLElement>(null)

  useEffect(() => {
    setFooterHeight(ref?.current?.clientHeight ?? 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize])

  return (
    <footer className="w-full bg-neutral-900 pb-5" ref={ref}>
      <div className="mx-auto max-w-screen-xl px-8">
        <div className="flex items-center justify-center pt-5 text-center font-extralight tracking-wider text-white sm:pt-7">
          {`Copyright © ${
            currentYear > CREATION_YEAR
              ? `${CREATION_YEAR} - ${currentYear}`
              : `${CREATION_YEAR}`
          } Gabriel Laderas - P. Iva 11186550965`}
        </div>
        <div className="flex items-center justify-center text-center font-extralight tracking-wider text-white">
          Created by Chaos
        </div>
      </div>
    </footer>
  )
}
