import {Translation} from '../models/Translation'

export const contact: Translation = {
  english: [
    {
      text: 'What are you waiting for? Contact me!',
    },
    {
      text: "Don't worry, beers are on me.",
    },
  ],
  italian: [
    {
      text: 'Cosa aspetti? Contattami!',
    },
    {
      text: 'Non ti preoccupare, le birre le pago io.',
    },
  ],
}
