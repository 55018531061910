import React, {useEffect} from 'react'
import {atom, useRecoilState} from 'recoil'
import {DropDownMenu} from './DropDownMenu'

export const websiteLanguage = atom<'eng' | 'ita'>({
  key: 'websiteLanguageState',
  default: 'eng',
})

export const LanguageSwitch: React.FC = () => {
  const [language, setLanguage] = useRecoilState(websiteLanguage)

  useEffect(() => {
    const userLang = navigator.language
    userLang.includes('it') ? setLanguage('ita') : setLanguage('eng')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = (e: UIEvent) => {
    e.preventDefault()
    setLanguage((previousState) => {
      return previousState === 'ita' ? 'eng' : 'ita'
    })
  }

  return (
    <DropDownMenu
      id="language-menu"
      icon={<></>}
      label={language === 'ita' ? 'ITA' : 'ENG'}
      items={[
        {
          label: language === 'ita' ? 'ENG' : 'ITA',
          onClick: (e) => handleClick(e),
        },
      ]}
    />
  )
}
