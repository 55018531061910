import React, {useState} from 'react'

interface Props {
  id: string
  forceOpen?: boolean
  label?: string
  withDivider?: boolean
  icon?: JSX.Element
  items: DDMItem[]
  withBackground?: boolean
}

export interface DDMItem {
  icon?: JSX.Element
  label: string
  desc?: string
  link?: string
  onClick?: (e?: any) => void
}

export const DropDownMenu: React.FC<Props> = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          aria-label={props.id}
          onClick={() => setIsOpen((previousState) => !previousState)}
          className={` ${
            props.withBackground
              ? 'border border-neutral-300 bg-neutral-800 shadow-sm'
              : ''
          } mx-4 flex w-full items-center justify-center rounded-md py-2 text-lg font-medium text-neutral-900 hover:text-orange-400`}
          id={props.id}
        >
          {props.label}

          {props.icon || (
            <svg
              width="20"
              height="20"
              fill="currentColor"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z" />
            </svg>
          )}
        </button>
      </div>

      {(props.forceOpen || isOpen) && (
        <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div
            className={`py-1 ${
              props.withDivider ? 'divide-y divide-neutral-100' : ''
            }`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {props.items.map((item) => {
              return (
                <div
                  key={item.label}
                  onClick={(e) => {
                    setIsOpen(false)
                    item?.onClick && item.onClick(e)
                  }}
                  className={`${
                    item.icon ? 'flex items-center' : 'block'
                  } text-md block cursor-pointer px-4 py-2 text-neutral-900 hover:bg-neutral-100 hover:text-orange-400`}
                  role="menuitem"
                >
                  {item.icon}

                  <span className="flex flex-col">
                    <span>{item.label}</span>
                    {item.desc && (
                      <span className="text-sm text-gray-400">{item.desc}</span>
                    )}
                  </span>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
