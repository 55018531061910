import {Logos} from '../models/Logos'

export const logosData: Logos[] = [
  {
    imageLocation: '/assets/logos/CARRERA.webp',
  },
  {
    imageLocation: '/assets/logos/GMT.webp',
  },
  {
    imageLocation: '/assets/logos/GQ.webp',
  },
  {
    imageLocation: '/assets/logos/MAX&CO.webp',
  },
  {
    imageLocation: '/assets/logos/VANITY_FAIR.webp',
  },
  {
    imageLocation: '/assets/logos/VOGUE.webp',
  },
]
