import React, {useState} from 'react'
import {createPortal} from 'react-dom'
import {Portfolio} from '../../models/Portfolio'
import {Detail} from './Detail'

export const Thumbnail: React.FC<Portfolio> = (props) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <div className="relative rounded-lg text-center shadow-lg sm:w-[calc(33%-0.3rem)] lg:w-[calc(33%-0.2rem)]">
        <img
          className="w-full rounded-lg"
          width="400"
          height="225"
          src={props.thumbnail}
          alt={props.name}
        />
        <div
          onClick={() => setShowModal(true)}
          className="absolute top-0 left-0 z-10 flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-neutral-500/75 opacity-0 transition-opacity duration-300 hover:opacity-100"
        >
          <h3 className="mx-auto whitespace-nowrap py-6 px-3 text-xl text-white max-md:text-sm sm:text-xs lg:text-xl">
            {props.name}
          </h3>
        </div>
      </div>
      {showModal &&
        createPortal(
          <Detail
            portfolio={props}
            onClick={() => setShowModal((previousState) => !previousState)}
          />,
          document.body
        )}
    </>
  )
}
