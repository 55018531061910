import React, {useEffect, useRef, useState} from 'react'
import ReactPlayer from 'react-player'
import {atom, useRecoilState} from 'recoil'
import {Portfolio} from '../../models/Portfolio'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {Description} from './Description'
import {PlayerContainer} from './PlayerContainer'

interface Props {
  portfolio: Portfolio
  onClick: () => void
}

export const videoLoading = atom<boolean>({
  key: 'videoLoadingState',
  default: false,
})

export const Detail: React.FC<Props> = (props) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useRecoilState(videoLoading)
  const [hideOverflow, setHideOverflow] = useState<boolean>(false)

  const isOverflowActive = (element: HTMLDivElement | null) => {
    if (element)
      return (
        element.offsetHeight < element.scrollHeight ||
        element.offsetWidth < element.scrollWidth
      )
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    setLoading(true)
    if (isOverflowActive(modalRef.current)) {
      setHideOverflow(true)
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={`${
        hideOverflow ? 'overflow-hidden' : 'scrollbar'
      } fixed top-0 z-20 h-full w-full bg-neutral-900/75 backdrop-blur`}
      ref={modalRef}
    >
      <div className="flex h-full flex-col items-center justify-center">
        <div className="absolute top-3 w-full max-w-7xl px-3 text-right">
          <FontAwesomeIcon
            icon={icon({name: 'circle-xmark', style: 'regular'})}
            className="h-7 w-7 cursor-pointer text-neutral-300 hover:text-neutral-100"
            onClick={props.onClick}
          />
        </div>
        <div className="px-1 py-16 md:px-4 md:py-12">
          {loading && (
            <div className="mx-auto flex h-[22rem] items-center max-sm:h-[12rem] md:h-[26rem] lg:h-[32rem]">
              <FontAwesomeIcon
                icon={icon({name: 'compact-disc', style: 'solid'})}
                spin
                className="h-12 w-12 text-neutral-200"
              />
            </div>
          )}
          <ReactPlayer
            url={props.portfolio.vimeoLink}
            controls
            onReady={() => setLoading(false)}
            wrapper={PlayerContainer}
          />
          <Description
            title={props.portfolio.title}
            note={props.portfolio.note}
            videoDesciption={props.portfolio.description}
          />
        </div>
        {hideOverflow && (
          <div
            className="absolute bottom-0 w-full cursor-pointer bg-gradient-to-t from-neutral-900 via-neutral-800 to-transparent pb-1 pt-5 text-center text-sm text-white"
            onClick={(e) => {
              e.stopPropagation()
              setHideOverflow(false)
            }}
          >
            Read more
          </div>
        )}
      </div>
    </div>
  )
}
